import * as React from 'react';
import { Link, graphql } from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { renderAst } from '../components/render-ast';

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const featuredImage =
    post?.frontmatter?.featuredimage || data?.home?.frontmatter?.featuredImage;
  const { previous, next } = data;
  return (
    <Layout location={location} title={siteTitle} featuredImage={featuredImage}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        featuredImage={featuredImage}
      />

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 className="main-heading" itemProp="headline">
            {post.frontmatter.title}
          </h1>
        </header>
        <section className="article-body" itemProp="articleBody">
          {renderAst(post.htmlAst)}
          <hr />

          <nav className="blog-post-nav">
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </section>
      </article>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(
      frontmatter: { templatekey: { eq: "blog-post" } }
      id: { eq: $id }
    ) {
      id
      excerpt(pruneLength: 160)
      html
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredpost
        featuredimage {
          childImageSharp {
            gatsbyImageData(
              width: 1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    previous: markdownRemark(
      frontmatter: { templatekey: { eq: "blog-post" } }
      id: { eq: $previousPostId }
    ) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(
      frontmatter: { templatekey: { eq: "blog-post" } }
      id: { eq: $nextPostId }
    ) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
